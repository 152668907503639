<template>
  <div
    class="AppConnections"
    :class="{
      'AppConnections--empty': !appConnectionsExist,
    }"
  >
    <AppConnectionsAppBar
      :creation-disabled="!licenseAllowsAppConnect"
      :show-actions="appConnectionsExist"
    />
    <AppConnectionsTable
      v-if="appConnectionsExist"
      :app-connections="appConnections"
    />
    <div
      v-else-if="dataLoaded"
      class="AppConnections__empty-state"
    >
      <EmptyState
        v-if="licenseAllowsAppConnect"
        :title="$t('connection.NoConnectionsYetM')"
      >
        <template #actions>
          <v-btn
            class="mt-6"
            color="primary"
            depressed
            :loading="actionInProgress"
            @click="createAppConnection"
          >
            <v-icon
              class="mr-2"
              v-text="'mdi-plus'"
            />
            {{ $t('connection.ConnectToHive') }}
          </v-btn>
        </template>
      </EmptyState>
      <EmptyState
        v-else
        :title="$t('connection.UpgradePlanM')"
      >
        <template #actions>
          <v-btn
            class="mt-6"
            color="primary"
            depressed
            href="https://hexway.io/pricing/#get-enterprise-price"
            target="_blank"
          >
            {{ $t('connection.UpgradePlan') }}
          </v-btn>
        </template>
      </EmptyState>
    </div>
  </div>
</template>

<script>
import AppConnectionsAppBar from '../components/AppConnectionsAppBar'
import AppConnectionsTable from '../components/AppConnectionsTable'
import EmptyState from '@/components/EmptyState'

export default {
  name: 'AppConnections',

  components: {
    AppConnectionsAppBar,
    AppConnectionsTable,
    EmptyState,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('connection.HiveConnections')),
    }
  },

  data() {
    return {
      dataLoaded: false,
      actionInProgress: false,
    }
  },

  computed: {
    appConnections() { return this.$store.getters['appConnections/appConnections'] },

    appConnectionsExist() { return this.appConnections && this.appConnections.length > 0 },

    licenseAllowsAppConnect() { return this.$store.state.license.information?.commonParameters.applicationConnect.isAvailable },
  },

  async created() {
    await this.$store.dispatch('license/getInformation')
    await this.$store.dispatch('appConnections/getAppConnections')
    this.dataLoaded = true
  },

  methods: {

    action(promise) {
      if (this.actionInProgress) {
        return Promise.reject(new Error(this.$t('connection.alreadyInProgress')))
      }
      this.actionInProgress = true
      return Promise.resolve(promise)
        .finally(() => { this.actionInProgress = false })
    },

    createAppConnection() {
      const { $store, action } = this

      const downloaded = $store.dispatch('appConnections/createAppConnection')
        .then(({ configUrl, supposedFilename }) => {
          const a = document.createElement('a')
          a.href = configUrl
          a.download = supposedFilename
          a.click()
        })
        .then(() => $store.commit('$snackbar/setMessage', {
          message: this.$t('connection.NewAppConnectionCreated'),
          timeout: 3000,
          hideCloseBtn: true,
        }))
      return action(downloaded)
        .then(() => { this.$store.dispatch('appConnections/getAppConnections') })
    },
  },

}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.AppConnections
  width: 100%
  padding: 24px 20px
  background: white
  min-height: calc(100vh - 64px)

  &--empty
    background: #EDF0FA

  &__empty-state
     padding: 20px 0 0 55px
     margin-top: 140px
</style>
